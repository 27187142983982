// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBwOM6iXZ4W9qIsh4J-5H7-Zh4PLVCh3zA",
    authDomain: "clarity-app-58305.firebaseapp.com",
    databaseURL: "https://clarity-app-58305-default-rtdb.firebaseio.com",
    projectId: "clarity-app-58305",
    storageBucket: "clarity-app-58305.appspot.com",
    messagingSenderId: "541715416984",
    appId: "1:541715416984:web:dd630ba4d651f62ada0ded",
    measurementId: "G-ELZJR8T48G"
  };
  
  export default firebaseConfig;